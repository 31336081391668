<template>
  <div>
    <list-header :tab="this.pageTab" />
    <f-container topBottom>
      <page-tabs v-model="pageTab" :items="items" @tab-click="changeTab" />
      <v-tabs-items v-model="pageTab">
        <v-tab-item :key="tabsRef.COURSES.tab">
          <report-course-list :ref="tabsRef.COURSES.ref" />
          <!-- <f-reports-view :key="pageTab" type="courses" /> -->
        </v-tab-item>
        <v-tab-item :key="tabsRef.STUDENTS.tab">
          <report-student-list :ref="tabsRef.STUDENTS.ref" />
          <!-- <f-reports-view :key="pageTab" type="students" /> -->
        </v-tab-item>
      </v-tabs-items>

      <br />
      <br />
      <br />
    </f-container>
  </div>
</template>

<script>
import ListHeader from '../../components/Reports/ListHeader.vue';
import PageTabs from '../../components/shared/PageTabs.vue';
import ReportCourseList from '../../components/Reports/ReportCourseList.vue';
import ReportStudentList from '../../components/Reports/ReportStudentList.vue';
import { getTabsCounters } from '../../services/report';

export default {
  components: {
    ListHeader,
    PageTabs,
    ReportCourseList,
    ReportStudentList,
  },
  data($vm) {
    const tabSlugs = {
      courses: 0,
      students: 1,
    };
    let tab = 0;
    if ($vm.$route.params.tab) {
      tab = tabSlugs[$vm.$route.params.tab] || 0;
    }
    return {
      pageTab: 0,
      tab,
      tabSlugs,
      tabsRef: {
        COURSES: {
          ref: 'courses',
          tab: 0,
        },
        STUDENTS: {
          ref: 'students',
          tab: 1,
        },
      },
      tabsAppends: {
        courses: '',
        students: '',
      },
      list: {
        0: [],
        1: [],
      },
      itemsCount: {
        0: 0,
        1: 0,
      },
      flags: {
        loaded: {
          0: false,
          1: false,
        },
      },
      page: {
        0: 1,
        1: 1,
      },
      search: {
        0: '',
        1: '',
      },
      limit: {
        0: 10,
        1: 10,
      },
      masterIds: {
        0: [],
        1: [],
      },
      status: '',
    };
  },
  computed: {
    title() {
      return this.$t('labels.reports');
    },
    items() {
      return [
        {
          icon: 'mdi-school-outline',
          title: this.$t('labels.courses'),
          append: this.tabsAppends.courses.toString(),
        },
        {
          icon: 'mdi-account-outline',
          title: this.$t('labels.students'),
          append: this.tabsAppends.students.toString(),
          route: 'report-list-students',
        },
      ];
    },
    tabs() {
      const items = [
        {
          key: 'courses',
          label: this.$t('labels.courses'),
        },
        {
          key: 'students',
          label: this.$t('labels.filterStudents'),
        },
      ];
      return items;
    },
  },
  created() {
    this.getTabsCounters();
  },
  mounted() {
    this.loadTabData();
  },
  methods: {
    async getTabsCounters() {
      this.tabsAppends = await getTabsCounters();
    },
    loadTabData() {
      let currentTabRef = '';
      switch (this.pageTab) {
        case this.tabsRef.COURSES.tab:
          currentTabRef = this.tabsRef.COURSES.ref;
          break;
        case this.tabsRef.STUDENTS.tab:
          currentTabRef = this.tabsRef.STUDENTS.ref;
          break;
      }

      setTimeout(() => {
        this.$refs[currentTabRef].loadData();
      }, 100);
    },
    changeTab(tabIndex) {
      this.pageTab = tabIndex;
      let tabLabel = '';
      switch (tabIndex) {
        case this.tabsRef.COURSES.tab:
          tabLabel = 'courses';
          break;
        case this.tabsRef.STUDENTS.tab:
          tabLabel = 'students';
          break;
      }

      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'tab-click',
        label: `view-${tabLabel}-tab`,
        value: tabIndex,
      });

      this.loadTabData();
    },
  },
};
</script>

<style lang="scss">
.chip-doing,
.chip-done,
.chip-total {
  display: inline-block;
  border-radius: 100px;
  padding: 6px 20px;
  margin-left: 6px;
}
.chip-done {
  color: #3f7a23;
  background-color: #e0e7e0;
}
.chip-doing {
  color: #f6932e;
  background-color: #fef6ee;
}
.chip-total {
  color: #236b9a;
  background-color: #e0ebf2;
}
.row-bold-title {
  font-weight: 700;
  font-size: 16px;
  width: 550px;
  display: inline-block;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 70px;
}
</style>
